.category-listing-sec{
    margin-top: 30px;
}

.category-listing-box{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    grid-gap: 2rem;
}

.category-listing-img {
    width: 100%;
    height: 20em;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.category-listing-card{
    width: 100%;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-radius: 10px;
    background-color: var(--secondary-color);
    margin: 0.5em;
}

.category-listing-info .category-listing-user-profile{
    margin-top: -4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-listing-info .category-listing-user-profile .category-listing-user-img {
    width: 10em;
    height: 10em;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #fff;
    padding: 2px;
    object-fit:cover;
}

.category-listing-info a {
    font-weight: 600;
    line-height: 1.5;
    color: #6f6f6f;
    font-size: 17px;
    margin-bottom: 0;
    text-align: center;
}

.category-listing-info h4{
    margin-top:0.5em;
}

.category-tag-sec{
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: #f6f7f8;
    width: 35%;
    margin: 10px auto;
    padding: 0.5em;
    border-radius: 10px;
}

.category-tag-sec h6{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
}

.category-tag-sec i{
    margin-right: 0.5em;
}

.category-listing-title{
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    text-transform: uppercase;
    color: #8a96a3;
    margin:2em 0;
}

.category-listing-post-count{
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-listing-post-count li {
    margin-top: 0;
    margin-right: 1.5em;
    background-color: #f6f7f8;
    padding: 0.5em;
    border-radius: 10px;
}

.category-listing-post-count li span{
    font-size: 14px;
    font-weight: 600;
    padding-left: 0.5em;
}

.category-listing-post-count-img{
    max-width: 2em;
}

.category-post-card{
    grid-template-columns: repeat(5,1fr);
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding:0 1.5em 1.5em;
}

.category-post-img{
    width: 100%;
    height: 5em;
    object-fit: cover;
    border-radius: 6px;
}

.post-lock{
    position: relative;
}

.post-lock:before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 0.5em;
}

.post-lock-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:50%;
    -webkit-transform: translate(-50% , -50%);
  -ms-transform: translate(-50% , -50%);
  transform: translate(-50% , -50%);
  left: 50%;
}

.post-lock-icon i{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    border:1px solid #dc354d;
    background-color: rgb(220 53 69 / 50%);
    border-radius: 50%;
    font-size: 1.2em;
    color: var(--secondary-color);
}

.category-list-sec{
    display: flex;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 1em;
}

.category-list-sec::-webkit-scrollbar{
    height: 0.75em;
    background-color: #f6f7f8;
}

.category-list-sec::-webkit-scrollbar-thumb
{
	border-radius: 1em;
	background-color: #555;
}

.category-list-sec.category-list-slider-sec{
    display: block;
}

.category-list-sec li{
    margin-top: 0;
    margin-right: 2em;
    background-color:  var(--secondary-color);
    padding: 10px 15px;
    border-radius: 6px;
    white-space: nowrap;
}

.category-list-sec li.active{
    background-color: var(--primary-color);
    color: #f6f7f8;
}

.category-list-sec li.active a{
    color: #f6f7f8 !important;
}

.category-list-sec li.clear-button{
    margin-top: 0;
    margin-right: 2em;
    background-color:  #f6f7f8;
    padding: 10px 15px;
    border-radius: 6px;
}

.category-list-sec li a{
    margin-bottom: 0;
    font-size: 1.3em;
    font-weight: 500;
    color: var(--primary-color);
}

.category-list-sec li.clear-button a{
    margin-bottom: 0;
    font-size: 1.3em;
    font-weight: 500;
    color: #212529;
}

.category-list-sec li.clear-button a:hover{
    color: #212529 !important;
}


.d-block {
    display: block !important;
}

.d-content {
    display: contents;
}