/* .live-streaming-post-sec{
    margin-top: 80px;
    margin-bottom: 4em;
} */

.live-streaming-post-box {
  width: 100%;
  /* padding: 2em;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-radius: 15px; */
  margin-top: 4em;
}

.live-streaming-post-img {
  width: 100%;
  height: 25em;
  object-fit: cover;
  border-radius: 8px;
}

.live-streaming-post-card
  .live-streaming-post-user-info
  .live-streaming-post-user-img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 2px solid #ca3939;
  background-color: #fff;
  padding: 2px;
  object-fit: cover;
}

.live-streaming-post-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
}

.live-streaming-post-user-info {
  display: flex;
  align-items: center;
}

.live-streaming-post-user-details {
  padding-left: 1.5em;
}

.live-streaming-post-user-details h4 {
  font-weight: 600;
  line-height: 1.5;
  color: #6f6f6f;
  font-size: 17px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7em;
}

.live-streaming-post-user-details p {
  font-weight: 500;
  line-height: 1.5;
  color: #6f6f6f;
  font-size: 12px;
  margin-bottom: 0;
}

.live-streaming-post-action-icons {
  display: flex;
  margin-bottom: 0;
}

.live-streaming-post-action-icons li {
  margin-top: 0;
  margin-right: 1em;
  font-size: 14px;
  font-weight: 600;
}

.live-streaming-post-action-icons li i {
  font-size: 18px;
}

.live-streaming-post-action-icons li:last-child {
  margin-right: 0;
}

.live-streaming-post-box {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 2rem;
}

button.btn.btn-default.dropdown-toggle {
  background: transparent;
  border: none;
  padding: 0px;
  display: inline-block;
}

.live-streaming-post-sec .bookmarkes-list.bookmarks-right-side .notify-title {
  color: var(--senary-color) !important;
}

.width-full {
  width: 100%;
  padding: 2em 0;
  padding-top: 0;
}

.explore-location-dropdown {
  margin-bottom: 0 !important;
}

.explore-location-dropdown .form-control {
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  text-transform: capitalize;
  border: 2px dashed #eee !important;
  border-radius: 8px !important;
  padding-left: 1em !important;
}

.explore-location-dropdown .form-control:focus {
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%) !important;
}

.explore-location-dropdown .form-control::placeholder {
  font-size: 14px;
  font-weight: 600;
}

.explore-tab-sec {
  padding-top: 80px;
  margin-top: 0;
  padding-bottom: 4em;
  background-color: #eeeeee;
}

.explore-tab-sec .nav-link {
  margin-right: 1em;
  background-color: #f6f7f8;
  padding: 10px 15px;
  border-radius: 6px;
  color: #8a96a3;
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 600;
}

.explore-tab-sec .nav-link.active {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.live-streaming-post-card.explore-card {
  position: relative;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-radius: 8px;
  padding: 8px;
  background-color: var(--quaternary-color);
}

.live-streaming-post-card.explore-card::before {
  content: "";
  position: absolute;
  top: 2%;
  right: 2%;
  left: 2%;
  width: 96%;
  height: 96%;
  border-radius: 8px;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  /* background: linear-gradient(bottom, #000, transparent);
    background: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent)); */
}

.live-streaming-post-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.live-streaming-post-card.explore-card .live-streaming-post-user-details h4 {
  color: var(--secondary-color);
}

.live-streaming-post-card.explore-card .live-streaming-post-action-icons li {
  color: #c9c9c9;
}

.live-streaming-post-card.explore-card .live-streaming-post-user-details p {
  color: #c9c9c9;
}

.explore-tab-sec .nav {
  justify-content: center;
  border-bottom: 1px solid #6666;
}

.explore-tab-sec .nav-link.active {
  background-color: transparent !important;
  color: var(--teritary-color) !important;
  border-bottom: 3px solid var(--primary-color);
}

.explore-tab-sec .nav-link {
  background-color: transparent !important;
  border-radius: 0;
  font-size: 1.6em;
}
